import React, { useContext } from "react";
import { CContainer, CHeader, CHeaderNav } from "@coreui/react";
import "../assets/css/common.css";
import AppHeaderDropdown from "./header/AppHeaderDropdown";
import AuthContext from "./shared/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";

const AppHeader = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return <Navigate to="/" />;
  }

  const { logout } = authContext;

  return (
    <CHeader
      position="sticky"
      style={{ backgroundColor: "white" }}
      className="mb-4  ScanHardware-main"
    >
      <CContainer fluid>
        <div
          onClick={() => navigate("/Home")}
          className=""
          style={{ fontFamily: "Expansiva, sans-serif" }}
        >
          <div className="justified-container ">
            <div className="justified-line ">
              <h3>Newton</h3>
            </div>
            <div className="justified-line">
              <p>Insights</p>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="nav-action-icons">
            <IoIosSearch size="30" />
            <IoNotificationsOutline size="30" />
          </div>
          <CHeaderNav className="user-name">
            <AppHeaderDropdown logout={logout} />
          </CHeaderNav>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
