import React from "react";
import GetTestStatus from "../GetTestStatus";
import { CiCircleCheck } from "react-icons/ci";
import { MdOutlineSpeed } from "react-icons/md";
import { BsExclamationCircle } from "react-icons/bs";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CButton,
} from "@coreui/react";

interface LatestUnitCalibration {
  old: boolean;
  dated: string;
}

type HandleUnitCalibration = () => Promise<void>;

interface CalibrationProps {
  latestUnitCalibration: LatestUnitCalibration;
  handleUnitCalibration: HandleUnitCalibration;
}

const Calibration: React.FC<CalibrationProps> = ({
  latestUnitCalibration,
  handleUnitCalibration,
}) => (
  <CCard style={{ backgroundColor: "#DAEEF5" }}>
    <CCardBody>
      <CCardTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <MdOutlineSpeed
            style={{
              color: "#1488f5",
              height: "150px",
            }}
            size="100"
          />
        </div>
        <h3
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Calibration
        </h3>
      </CCardTitle>

      <CCardText>
        <CRow
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CCol
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "2.8rem",
            }}
          >
            <CRow>
              <small style={{ color: "grey" }}>Status</small>
            </CRow>

            <CRow>
              <small
                style={{
                  fontFamily: "Expansiva, sans-serif",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <GetTestStatus test={!latestUnitCalibration?.old} />
                {!latestUnitCalibration?.old ? "Active" : "Inactive"}
              </small>
            </CRow>
          </CCol>
          <CCol
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "2.8rem",
            }}
          >
            <CRow>
              <small style={{ color: "grey" }}>Previous Date</small>
            </CRow>

            <CRow>
              <small
                style={{
                  fontFamily: "Expansiva, sans-serif",
                  fontSize: "0.7rem",
                }}
              >
                {latestUnitCalibration?.dated.replace(/-/g, ".").slice(0, 10)}
              </small>
            </CRow>
          </CCol>
        </CRow>
      </CCardText>
      <div className="w-100">
        <CButton
          className="centeralized no-margin w-100"
          style={{
            backgroundColor: latestUnitCalibration?.old ? "red" : undefined,
          }}
          color="primary"
          onClick={handleUnitCalibration}
        >
          {latestUnitCalibration?.old ? (
            <BsExclamationCircle style={{ marginRight: "5px" }} size="20" />
          ) : (
            <CiCircleCheck
              data-testid={"ci-circle-check-icon"}
              style={{ marginRight: "5px" }}
              size="20"
            />
          )}
          {!latestUnitCalibration.old ? "Completed" : "Calibrate Unit"}
        </CButton>
      </div>
    </CCardBody>
  </CCard>
);

export default Calibration;
